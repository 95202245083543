import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { Storage } from "aws-amplify";
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReportIssueDialogProps from './ReportIssueDialogProps';

export default function ReportIssueDialog(props: ReportIssueDialogProps) {

    useEffect(() => {
        console.log('dialog', props.dialogOpen);
        setDialogOpen(props.dialogOpen);
    }, [props.dialogOpen]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [reportIssueText, setReportIssueText] = useState('')
    const handleReportIssueTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReportIssueText(e.target.value);
    }
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleDialogSubmit = async () => {
        const issueId = (props.resource?.type === 'video' ? 'Video' : 'Document') + ' ' + props.resource?.title + ' ' + uuidv4();
        const issueInfo = {
            issueText: reportIssueText,
            resource: props.resource
        }
        try {
            await Storage.put(`reported_issues/${issueId}`, JSON.stringify(issueInfo));
            setDialogOpen(false);
        } catch (err) {
            console.log('Error reporting issue', err);
        }

    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Report Issue"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Report an issue with this page:
                        <TextField fullWidth variant="filled" multiline rows={5} onChange={handleReportIssueTextChange} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDialogSubmit} color="primary" autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}