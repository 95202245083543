import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Badge, Hidden, IconButton, styled, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import VideoService from '../ResourceGrid/Parts/Video/VideoService';
import HeaderProps from './HeaderProps';
import logo from './Resources/logo-horiz.png';

export default function Header(props: HeaderProps) {

    const CustomAppBar = styled(AppBar)(({ theme }) => ({
        zIndex: theme.zIndex.drawer + 1
    }));

    const CustomToolbar = styled(Toolbar)(({ theme }) => ({
        color: theme.palette.text.primary,
        backgroundColor: 'white',
        ...theme.mixins.toolbar
    }));

    const SectionDesktop = styled('div')(({ theme }) => ({
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flex: 1,
            position: 'absolute',
            right: 25
        },
    }));

    const MenuButton = styled(IconButton)(({ theme }) => ({
        marginRight: theme.spacing(2)
    }));

    const [email, setEmail] = useState<string>();
    useEffect(() => {
        if (props.user) {
            props.user.getUserAttributes((err, result) => {
                setEmail(result?.find(a => a.getName() === 'email')?.getValue());
            });
        }
    });

    /**
     * The number of videos the user has completed
     */
    const [numberOfWatchedVideos, setNumberOfWatchedVideos] = useState<number>();
    useEffect(() => {
        if (props.user) {
            VideoService.getNumberOfWatchedVideos(props.user.getUsername()).then((nw) => setNumberOfWatchedVideos(nw));
        }
    });

    return (
        <React.Fragment>
            <CustomAppBar id="app-bar">
                <CustomToolbar>
                    <Hidden smUp>
                        <MenuButton onClick={props.onMenuClick} edge="start" color="inherit" aria-label="menu">
                            <MenuIcon />
                        </MenuButton>
                    </Hidden>
                    <img src={logo} style={{
                        width: 265,
                        height: 'auto',
                        marginRight: 25
                    }} />
                    <Hidden smDown>
                        <Typography variant="h4" component="h2">
                            Training Hub
                        </Typography>
                    </Hidden>
                    <SectionDesktop>
                        <Typography variant="subtitle1" component="h2" style={{ padding: 10 }}>
                            {email}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit">
                            <Badge badgeContent={numberOfWatchedVideos} color="error" />
                        </IconButton>
                    </SectionDesktop>
                </CustomToolbar>
            </CustomAppBar>
            <Toolbar id="back-to-top-anchor" />
        </React.Fragment>
    );
}