/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createResource = /* GraphQL */ `
  mutation CreateResource(
    $input: CreateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    createResource(input: $input, condition: $condition) {
      id
      type
      title
      description
      resourcePath
      thumbnailPath
      relevance
      tags
      appAsString
      createdAt
      updatedAt
    }
  }
`;
export const updateResource = /* GraphQL */ `
  mutation UpdateResource(
    $input: UpdateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    updateResource(input: $input, condition: $condition) {
      id
      type
      title
      description
      resourcePath
      thumbnailPath
      relevance
      tags
      appAsString
      createdAt
      updatedAt
    }
  }
`;
export const deleteResource = /* GraphQL */ `
  mutation DeleteResource(
    $input: DeleteResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    deleteResource(input: $input, condition: $condition) {
      id
      type
      title
      description
      resourcePath
      thumbnailPath
      relevance
      tags
      appAsString
      createdAt
      updatedAt
    }
  }
`;
export const createTest = /* GraphQL */ `
  mutation CreateTest(
    $input: CreateTestInput!
    $condition: ModelTestConditionInput
  ) {
    createTest(input: $input, condition: $condition) {
      id
      name3
      test
      createdAt
      updatedAt
    }
  }
`;
export const updateTest = /* GraphQL */ `
  mutation UpdateTest(
    $input: UpdateTestInput!
    $condition: ModelTestConditionInput
  ) {
    updateTest(input: $input, condition: $condition) {
      id
      name3
      test
      createdAt
      updatedAt
    }
  }
`;
export const deleteTest = /* GraphQL */ `
  mutation DeleteTest(
    $input: DeleteTestInput!
    $condition: ModelTestConditionInput
  ) {
    deleteTest(input: $input, condition: $condition) {
      id
      name3
      test
      createdAt
      updatedAt
    }
  }
`;
export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo(
    $input: CreateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    createUserInfo(input: $input, condition: $condition) {
      id
      videos {
        videoId
        location
        progress
        secondsWatched
      }
      audios {
        audioId
        location
        progress
        secondsWatched
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo(
    $input: UpdateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    updateUserInfo(input: $input, condition: $condition) {
      id
      videos {
        videoId
        location
        progress
        secondsWatched
      }
      audios {
        audioId
        location
        progress
        secondsWatched
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserInfo = /* GraphQL */ `
  mutation DeleteUserInfo(
    $input: DeleteUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    deleteUserInfo(input: $input, condition: $condition) {
      id
      videos {
        videoId
        location
        progress
        secondsWatched
      }
      audios {
        audioId
        location
        progress
        secondsWatched
      }
      createdAt
      updatedAt
    }
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
      appName
      landingPages {
        id
        appID
        title
        url
        createdAt
        updatedAt
      }
      menuVisible
      createdAt
      updatedAt
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
      appName
      landingPages {
        id
        appID
        title
        url
        createdAt
        updatedAt
      }
      menuVisible
      createdAt
      updatedAt
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
      appName
      landingPages {
        id
        appID
        title
        url
        createdAt
        updatedAt
      }
      menuVisible
      createdAt
      updatedAt
    }
  }
`;
export const createAppLandingPage = /* GraphQL */ `
  mutation CreateAppLandingPage(
    $input: CreateAppLandingPageInput!
    $condition: ModelAppLandingPageConditionInput
  ) {
    createAppLandingPage(input: $input, condition: $condition) {
      id
      appID
      title
      url
      createdAt
      updatedAt
    }
  }
`;
export const updateAppLandingPage = /* GraphQL */ `
  mutation UpdateAppLandingPage(
    $input: UpdateAppLandingPageInput!
    $condition: ModelAppLandingPageConditionInput
  ) {
    updateAppLandingPage(input: $input, condition: $condition) {
      id
      appID
      title
      url
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppLandingPage = /* GraphQL */ `
  mutation DeleteAppLandingPage(
    $input: DeleteAppLandingPageInput!
    $condition: ModelAppLandingPageConditionInput
  ) {
    deleteAppLandingPage(input: $input, condition: $condition) {
      id
      appID
      title
      url
      createdAt
      updatedAt
    }
  }
`;
