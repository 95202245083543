import PlayArrow from '@mui/icons-material/PlayArrow';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid, IconButton, styled, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { AudioInput, VideoProgressInput } from '../../../API';
import ResourceService from '../ResourceService';
import AudioService from './Audio/AudioService';
import ResourceThumbnailProps from './ResourceThumbnailProps';
import VideoService from './Video/VideoService';

const DESCRIPTION_LINES = 3;

export default function ResourceThumbnail(props: ResourceThumbnailProps) {

    const isMedia = () => {
        return props.resource.type === 'audio' || props.resource.type === 'video';
    }

    const Root = styled('div')(({ theme }) => ({
        [theme.breakpoints.up('sm')]: {
            width: 200,
            marginRight: 75
        }
    }))

    /**
     * Get the presigned thumbnail URL
     */
    const [thumbnailURL, setThumbnailURL] = useState<string>();
    useEffect(() => {
        if (props.resource.thumbnailPath) {
            ResourceService.getPresignedURL(props.resource.thumbnailPath).then((t) => {
                setThumbnailURL(t);
            })
        }
        else if (props.resource.type === 'audio') {
            ResourceService.getPresignedURL('audio/speaker-thumb.png').then((t) => {
                setThumbnailURL(t);
            })
        }
    }, []);

    const [videoProgress, setVideoProgress] = useState<VideoProgressInput | null>();
    const [audioProgress, setAudioProgress] = useState<AudioInput | null>();

    /**
     * Load the video
     */
    useEffect(() => {
        if (videoProgress === undefined && props.resource.type === 'video') {
            loadVideoProgress();
        }
        if (audioProgress === undefined && props.resource.type === 'audio') {
            loadAudioProgress();
        }
    });

    const loadVideoProgress = async () => {
        try {
            const vp = await VideoService.getProgress(props.user.getUsername(), props.resource?.id || '');
            setVideoProgress(vp || null);
        } catch (err) {
            console.log('Err', err);
        }
    }

    const loadAudioProgress = async () => {
        try {
            const ap = await AudioService.getProgress(props.user.getUsername(), props.resource?.id || '');
            setAudioProgress(ap || null);
        } catch (err) {
            console.log('Err', err);
        }
    }

    return (
        <Root>
            <Typography gutterBottom noWrap variant="subtitle1" component="h2">
                <strong>{props.resource.title || "Untitled Resource"}</strong>
            </Typography>
            <Link to={"/" + props.resource.type + "/" + props.resource.id}>
                <div
                    style={props.lockAspectRatio
                        ?
                        {
                            position: 'relative',
                            paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */
                        }
                        :
                        undefined
                    }
                >
                    <div
                        style={props.lockAspectRatio
                            ?
                            {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                border: 'solid rgb(123, 123, 123) 1px',
                                backgroundColor: 'black',
                                height: '100%',
                                width: '100%'
                            }
                            :
                            undefined
                        }
                    >
                        {props.showOverlay && <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            <PlayArrow
                                fontSize='large'
                                sx={{
                                    color: 'white',
                                    opacity: '70%',
                                    fontSize: '4em',
                                    zIndex: 1
                                }}
                            /></div>}
                        {(videoProgress?.progress === 1 || audioProgress?.progress === 1) && <div style={{
                            zIndex: 1,
                            width: '15%',
                            height: '22%',
                            backgroundColor: 'red',
                            color: 'white',
                            position: 'absolute',
                            right: 0,
                            top: 0
                        }}><VisibilityIcon style={{ width: '100%', height: '100%' }} /></div>}
                        <img style={{
                            width: isMedia() ? '100%' : '150px',
                            height: isMedia() ? '100%' : undefined,
                            position: isMedia() ? 'absolute' : undefined,
                            top: 0
                        }}
                            src={thumbnailURL} />
                        {(videoProgress?.progress || audioProgress?.progress) &&
                            <div
                                style={{
                                    width: ((videoProgress?.progress || 0) * 100) + ((audioProgress?.progress || 0) * 100) + '%',
                                    height: '4%',
                                    backgroundColor: 'red',
                                    position: 'absolute',
                                    bottom: 0
                                }}
                            >
                            </div>}
                    </div>
                </div>
            </Link>
            <Grid container>
                <Grid item sx={{ width: '90%' }}>
                    <Tooltip
                        placement="top"
                        disableHoverListener={!props.resource.description}
                        disableTouchListener={!props.resource.description}
                        disableFocusListener={!props.resource.description}
                        enterDelay={500}
                        enterTouchDelay={250}
                        title={
                            <React.Fragment>
                                <Typography variant="subtitle2" component="h2">
                                    {props.resource.description}
                                </Typography>
                            </React.Fragment>
                        }>
                        <Typography
                            variant="subtitle2"
                            component="h2"
                            sx={{
                                paddingTop: 5,
                                height: DESCRIPTION_LINES * 23,
                                overflow: 'hidden',
                                position: 'relative',
                                "&::after": {
                                    content: "''",
                                    textAlign: 'right',
                                    position: 'absolute',
                                    bottom: '5px',
                                    right: 0,
                                    width: '50%',
                                    height: '1.2em',
                                    background: 'linear-gradient(to right, rgba(230, 230, 230, 0), rgba(230, 230, 230, 1) 50%)'
                                }
                            }}
                        >
                            {props.resource.description}
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item sx={{ width: '10%' }}>
                    <IconButton sx={{
                        marginLeft: 'auto'
                    }}
                        size="small"
                        onClick={() => props.onShareLink(props.resource)}
                    >
                        <ShareIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
        </Root>
    );
}