import { /* makeStyles, */ /* Theme, */ Typography } from "@mui/material";
import React from "react";
import { useDev } from "../BaseUrl";
import Categories from "../Categories";
import ContactInformation from "./WDContactInformation";

// const useStyles = makeStyles((theme: Theme) => ({}));

export default function EmployeeLinks() {
  //const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h4">Quick Links for Employees</Typography>
      <p>
        Welcome to Workday for Employees! <br />
        With a Go Begin Date of October 5, 2020, we are excited for employees to begin learning about Workday and
        exploring all of the self service options. <br />
        With Workday, you’ll be able to view your own information in your Workday profile and perform many tasks right
        from your mobile device.
        <br />
        Employees will have the ability to:
        <ul>
          <li>View accruals and request time off</li>
          <li>View paycheck and update payment elections</li>
          <li>Update contact information and profile photo</li>
          <li>View company org charts</li>
          <li>Manage performance reviews, talent profile and individual talent items</li>
          <li>Complete assigned courses and self-enroll in Erickson University courses of your choice</li>
          <li>Update career preferences, view and apply for open positions</li>
          <li>And more!</li>
        </ul>
      </p>

      <br />
      
      <Categories s3file={useDev ? "WDEmployeeJSON_dev.json" : "WDEmployeeJSON_prod.json"} /> 

      <br />
      <ContactInformation />
    </React.Fragment>
  );
}
