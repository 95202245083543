import { Grid, styled, TextField } from '@mui/material';
import React, { useCallback, useState } from 'react';
import SearchBoxProps from './SearchBoxProps';

export default function SearchBox(props: SearchBoxProps) {

    const CustomTextField = styled(TextField)(({ theme }) => ({
        [theme.breakpoints.up('md')]: {
            width: 400
        },
        backgroundColor: 'white'
    }));

    const [searchText, setSearchText] = useState<string>('');

    const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        props.onSearch(e.target.value);
    }

    useCallback((e: any) => setSearchText(e.target.value), [searchText]);

    return (
        <div>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <CustomTextField
                        size="small"
                        label="Search"
                        type="search"
                        variant="outlined"
                        value={searchText}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                props.onSearch(searchText);
                            }
                        }}
                        onChange={handleSearchTextChange}
                    />
                </Grid>
                <Grid item>
                    {/*<IconButton onClick={e => props.onSearch(searchText)} size="small">
                        <SearchIcon fontSize="large" />
                    </IconButton>*/}
                </Grid>
            </Grid>
        </div>
    );
}