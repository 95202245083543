import { /* makeStyles, Theme, */ Typography } from "@mui/material";
import React from "react";
import { useDev } from "../BaseUrl";
import Categories from "../Categories";
import ContactInformation from "./WDContactInformation";

// const useStyles = makeStyles((theme: Theme) => ({}));

export default function FinanceLinks() {
  // const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h4">Quick Links for Finance</Typography>

      <br />
      <Categories s3file={useDev ? "FinanceJSON_dev.json" : "FinanceJSON_prod.json"} /> 
      <br />

      <ContactInformation />
    </React.Fragment>
  );
}
