import { List, ListItem, ListItemText } from '@mui/material';
import React from 'react';
import { Link } from "react-router-dom";

export default function Home() {

    return (
        <React.Fragment>
            <List>
                <ListItem button key="">
                    {/*<ListItemIcon><HomeIcon className={classes.icon} /></ListItemIcon>*/}
                    <ListItemText>
                        <Link to="/document">Training Documents</Link>
                    </ListItemText>
                </ListItem>
                <ListItem button key="">
                    {/*<ListItemIcon><LabelIcon className={classes.icon} /></ListItemIcon>*/}
                    <ListItemText>
                        <Link to="/video">Training Videos</Link>
                    </ListItemText>
                </ListItem>
            </List>
        </React.Fragment>
    );
}