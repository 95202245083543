import { Button, Grid, MenuItem, styled, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import SideBarData from "../SideBar/SideBarData";
import SideBarService from "../SideBar/SideBarService";
import TagContainer from './TagContainer';
import UploadInputFieldProps from './UploadInputFieldProps';

const LabelGrid = styled(Grid)(theme => ({
    display: 'flex'
}));

export default function UploadInputField(props: UploadInputFieldProps) {

    const [appMenuItems, setAppMenuItems] = React.useState<SideBarData[]>();
    const [error, setError] = React.useState<string>();

    useEffect(() => {
        const loadMainAppItems = async () => {
            try {
                const appMainMenuItemsQuery = await SideBarService.getAllApplications();
                const appMainMenuItems = appMainMenuItemsQuery.data?.listApplications;
                setAppMenuItems(appMainMenuItems?.items as SideBarData[]);

                console.log(appMenuItems);
            } catch (err) {
                const message = "Error loading data";
                setError(`${message}: ${err}`);
                console.log(message, err);
            }

        };
        loadMainAppItems();
    }, []);

    const renderControl = (type: string | undefined) => {
        switch (type) {
            case 'file':
                return (
                    <Button variant="contained" color="primary" component="label">
                        Select {props.label}
                        <input required type="file" name="file" onChange={props.onChange} accept={props.accept} style={{ display: "none" }} />
                    </Button>
                );
            case 'select':
                return (
                    <TextField id="select" size="small" fullWidth rows={props.rows} select onChange={props.onChange} sx={{ backgroundColor: 'white' }}>
                        <MenuItem value="">Select</MenuItem>
                        {appMenuItems &&
                            appMenuItems.map((appMenuItem, appMenuItemIndex) => (
                                <MenuItem value={appMenuItem.appName}>{appMenuItem.appName}</MenuItem>
                            ))}
                    </TextField>);
            case 'tags':
                return (<TagContainer onChange={props.onChange} edit={false} existingTags={null} />);
            default:
                return (
                    <TextField
                        size="small"
                        fullWidth
                        rows={props.rows}
                        multiline={props.multiline}
                        variant="outlined"
                        onChange={props.onChange}
                        sx={{ backgroundColor: 'white' }}
                    />);
        }
    }

    return (
        <Grid container spacing={3}>
            <LabelGrid
                item
                xs={12}
                sm={props.labelWidth}
            >
                <Typography variant="h6">{props.label}
                    <sup style={{
                        color: 'red',
                        fontWeight: 'bold'
                    }}>
                        {props.required ? '*' : '\u00A0'}
                    </sup></Typography>
            </LabelGrid>
            <Grid item xs={12} sm={(12 - props.labelWidth) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12}>
                {renderControl(props.type)}
            </Grid>
        </Grid>
    );
}