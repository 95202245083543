import React from "react";
import { Typography } from "@mui/material";

const ContactInformation = () => {
  return (
    <React.Fragment>
      <Typography variant="h6">
        For answers to your questions: <br />
        Phone: (Internal) AskHR or 2-7547 <br />
        (External) 1-833-43-ASKHR
        <br />
        Email: AskHr@erickson.com <br />
      </Typography>
    </React.Fragment>
  );
};

export default ContactInformation;
