import ReportIcon from '@mui/icons-material/Report';
import { Box, Grid, IconButton } from '@mui/material';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import { Subject } from 'rxjs';
import { GetResourceQuery, UpdateResourceInput } from '../../../../API';
import ResourceService from '../../ResourceService';
import ReportIssueDialog from '../Shared/ReportIssueDialog';
import ResourcePage from '../Shared/ResourcePage';
import VideoPlayer from './VideoPlayer';
import VideoService from './VideoService';

const mainSubject = new Subject();

export default function VideoPage() {

    const { id } = useParams<{ id: string }>();

    const [error, setError] = useState<string>('');

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const [seekComplete, setSeekComplete] = useState<boolean>(false);
    const [userPromise, setUserPromise] = useState<Promise<CognitoUser>>();

    /**
     * Load the user object
     */
    useEffect(() => {
        if (!userPromise) {
            setUserPromise(Auth.currentAuthenticatedUser());
        }
    });

    const [user, setUser] = useState<CognitoUser>();
    if (!user) {
        Auth.currentAuthenticatedUser().then((u) => {
            console.log('User', u);
            setUser(u);
        });
    }

    /**
     * Set the initial progress of the video
     * 
     * @param playerRef video player reference
     */
    const handleReady = async (playerRef: React.MutableRefObject<ReactPlayer | null>) => {
        const user = await userPromise;
        if (id && user) {
            const progress = await VideoService.getProgress(user.getUsername(), id);
            if (progress && !seekComplete) {
                console.log('Seeking to', progress?.location);
                playerRef.current?.seekTo(progress?.location || 0, 'fraction');
                setSeekComplete(true);
            }
        }
    }

    /**
     * Save the video progress
     * 
     * @param playerRef video player reference
     */
    const handleSaveState = async (playerRef: React.MutableRefObject<ReactPlayer | null>) => {
        const user = await userPromise;
        console.log('playerRef', playerRef, playerRef?.current?.getCurrentTime(), playerRef?.current?.getDuration());
        if (id && user && playerRef?.current?.getCurrentTime() && playerRef?.current?.getDuration()) {
            const location = playerRef?.current?.getCurrentTime() / playerRef?.current?.getDuration();
            console.log('Saving location', location);
            await VideoService.saveProgress(user.getUsername(), id, location, playerRef?.current?.getCurrentTime());
            if (location === 1) {
                mainSubject.next('VIDEO_COMPLETED');
            }
        }
    }

    const handleVideoUpdate = async (resource: UpdateResourceInput) => {
        try {
            await ResourceService.updateResource(resource);
        } catch (err: any) {
            console.log('ERR', err.errors[0].message);
            setError(err.errors[0].message);
        }
    }

    const [resource, setResource] = useState<GetResourceQuery['getResource']>();
    const [presignedVideoURL, setPresignedVideoURL] = useState<string>();
    const handleResourceLoaded = (resource: GetResourceQuery['getResource']) => {
        setResource(resource);
        if (resource?.resourcePath) {
            ResourceService.getPresignedURL(resource.resourcePath).then((u) => {
                setPresignedVideoURL(u);
            })
        }
    }

    return (
        <ResourcePage user={user} id={id} onResourceUpdate={handleVideoUpdate} onResourceLoaded={handleResourceLoaded}>
            {error && <h5 style={{ color: 'red' }}>{error}</h5>}
            {presignedVideoURL && <VideoPlayer url={presignedVideoURL} onSaveState={handleSaveState} onReady={handleReady} />}
            <div style={{ maxWidth: 800 }}>
                <Grid container>
                    <Grid item sm={11}>
                        <Box paddingBottom={2} sx={{ fontSize: '0.9em' }}>
                            Last Updated: {resource?.updatedAt && (new Date(resource?.updatedAt)).toLocaleDateString()} {resource?.updatedAt && (new Date(resource?.updatedAt)).toLocaleTimeString()}
                        </Box>
                    </Grid>
                    <Grid item sm={1}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                        >
                            <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => setDialogOpen(true)}>
                                <ReportIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {resource && <ReportIssueDialog resource={resource} dialogOpen={dialogOpen} />}
        </ResourcePage>
    );
}