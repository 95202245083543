import { /* makeStyles, Theme, */ Typography } from "@mui/material";
import React from "react";
import { useDev } from "../BaseUrl";
import Categories from "../Categories";
import ContactInformation from "./WDContactInformation";

// const useStyles = makeStyles((theme: Theme) => ({}));

export default function RecruiterLinks() {
    // const classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h4">Quick Links for Recruiters</Typography> 

            <br />
            <Categories s3file={useDev ? "RecruitJSON_dev.json" : "RecruitJSON_prod.json"} /> 
            <br />

            <ContactInformation />
        </React.Fragment>
    );
}
