import FilterListIcon from '@mui/icons-material/FilterList';
import { FormControl, InputAdornment } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import SideBarData from "../SideBar/SideBarData";
import SideBarService from "../SideBar/SideBarService";

interface AppFilterProps {
    resourceType: string;
    onFilter: (app: string) => void;
}

export default function AppFilter(props: AppFilterProps) {
    const [appMenuItems, setAppMenuItems] = React.useState<SideBarData[]>();
    const [error, setError] = useState<string>();
    const [appFilter, setAppFilter] = useState<string>('all');

    useEffect(() => {
        const loadMainAppItems = async () => {
            try {
                const appMainMenuItemsQuery = await SideBarService.getAllApplications();
                const appMainMenuItems = appMainMenuItemsQuery.data?.listApplications;
                setAppMenuItems(appMainMenuItems?.items as SideBarData[]);
                setAppFilter(appFilter);
                console.log(appFilter);

                console.log(appMenuItems);
            } catch (err) {
                const message = "Error loading data";
                setError(`${message}: ${err}`);
                console.log(message, err);
            }

        };
        loadMainAppItems();
    }, []);

    const handleAppFilter = (e: SelectChangeEvent<string>) => {
        props.onFilter(e.target.value);
        setAppFilter(e.target.value);
    }

    const getResourceTypeName = () => {
        if (props.resourceType === 'audio') {
            return props.resourceType;
        }
        return `${props.resourceType}s`
    }

    return (
        <>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Select
                fullWidth
                onChange={handleAppFilter}
                value={appFilter}
                size="small"
                startAdornment={
                    <InputAdornment position="start">
                        <FilterListIcon />
                    </InputAdornment>
                }
                sx={{
                    backgroundColor: 'white'
                }}
            >
                <MenuItem value="all">All {getResourceTypeName()}</MenuItem>
                {appMenuItems &&
                    appMenuItems.map(appMenuItem => (
                        <MenuItem value={appMenuItem.appName}>{appMenuItem.appName}</MenuItem>
                    ))
                }
            </Select>
        </>
    );
}