import '@aws-amplify/ui-react/styles.css';
import {
  Box,
  createMuiTheme,
  CssBaseline,
  Link,
  styled, ThemeProvider,
  Typography
} from "@mui/material";
import { CognitoUser } from "amazon-cognito-identity-js";
import Amplify, { Auth, Hub } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BreadcrumbsRoute } from "use-react-router-breadcrumbs";
import awsmobile from "./aws-exports";
import AdminPage from "./Components/Admin/AdminPage";
import BreadcrumbBar from "./Components/BreadcrumbBar/BreadcrumbBar";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import EMSLinks from "./Components/LandingPages/EMS/EMSEvents";
import EHWCLinks from "./Components/LandingPages/WorkDay/WDEHWC";
import EmployeeLinks from "./Components/LandingPages/WorkDay/WDEmployee";
import FinanceLinks from "./Components/LandingPages/WorkDay/WDFinance";
import HRBusPartnerLinks from "./Components/LandingPages/WorkDay/WDHRBusPartner";
import ManagerLinks from "./Components/LandingPages/WorkDay/WDManager";
import RecruiterLinks from "./Components/LandingPages/WorkDay/WDRecruiter";
import DocumentPage from "./Components/ResourceGrid/Parts/Document/DocumentPage";
import VideoPage from "./Components/ResourceGrid/Parts/Video/VideoPage";
import ResourceGrid from "./Components/ResourceGrid/ResourceGrid";
import AudioPage from "./Components/ResourceGrid/Parts/Audio/AudioPage";
import SideBar from "./Components/SideBar/SideBar";
import UploadPage from "./Components/Upload/UploadPage";

Amplify.configure(awsmobile);

const drawerWidth = 250;

const theme = createMuiTheme({
  palette: {
    text: {
      primary: "rgb(20, 59, 89)",
    },
    background: {
      default: "rgb(230, 230, 230)",
    },
  },
});

theme.typography.h2 = {
  [theme.breakpoints.down("xs")]: {
    fontSize: "1.65rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.4rem",
  },
};

theme.typography.subtitle1 = {
  [theme.breakpoints.down("xs")]: {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
  },
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Erickson Living
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const App: React.FC = (props) => {
  const [user, setUser] = useState<CognitoUser>();

  const logout = useCallback(() => Auth.signOut(), []);

  Hub.listen("auth", ({ payload: { event, data } }) => {
    switch (event) {
      case "customOAuthState":
        const targetUrl = localStorage.getItem("targetUrl") || "/";
        localStorage.removeItem("targetUrl");
        window.location.pathname = targetUrl;
    }
  });

  useEffect(() => {
    // FIXME: Federated sign-in race condition: https://github.com/aws-amplify/amplify-js/milestone/23
    (async () => {
      try {
        // Needed because of the race condition
        await new Promise((r) => setTimeout(r, 3000));
        console.log("Initial get user");
        const authUser = await Auth.currentAuthenticatedUser();
        setUser(authUser);
      } catch (err) {
        console.log("Err", err);
        localStorage.setItem("targetUrl", window.location.pathname);
        Auth.federatedSignIn({ customProvider: "Erickson", customState: "redirect" });
      }
    })();
  }, []);

  const breadcrumRoutes = [
    { path: "/workday", breadcrumb: "Workday Quick Links" },
    { path: "/document", breadcrumb: "Training Documents" },
    { path: "/document/:id", breadcrumb: "Document" },
    { path: "/video", breadcrumb: "Training Videos" },
    { path: "/video/:id", breadcrumb: "Video" },
    { path: "/video-upload", breadcrumb: "Video Upload" },
    { path: "/document-upload", breadcrumb: "Document Upload" },
    { path: "/admin", breadcrumb: "Admin Console" },
    { path: "/workday/employee", breadcrumb: "Employee" },
    { path: "/workday/manager", breadcrumb: "Manager" },
    { path: "/workday/hrbuspartner", breadcrumb: "HR Business Partner" },
    { path: "/workday/ehwc", breadcrumb: "EHWC" },
    { path: "/workday/sdc", breadcrumb: "SDC" },
    { path: "/workday/recruiter", breadcrumb: "Recruiter" },
    { path: "/workday/finance", breadcrumb: "Finance" },
    { path: "/ems/emsmyevents", breadcrumb: "EMS/My Events" },
    { path: "/audio", breadcrumb: "Podcasts" },
    { path: "/audio/:id", breadcrumb: "Podcast" },
    { path: "/podcast-upload", breadcrumb: "Podcast Upload" },
  ] as BreadcrumbsRoute[];

  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const handleMenuClick = () => {
    setMenuVisible(!menuVisible);
  };

  const Container = styled('div')(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
    paddingLeft: "2%",
    paddingRight: "2%",
    marginTop: 35,
    width: `calc(100% - ${drawerWidth}px)`,
  }));

  const App = () => {
    return (
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Header user={user} onLogOut={logout} onMenuClick={handleMenuClick} />

          <main>
            <Container>
              <Box paddingTop={1} paddingBottom={2}>
                <BreadcrumbBar routes={breadcrumRoutes} />
              </Box>
              <Routes>
                <Route path="/admin" element={<AdminPage />} />
                {user &&
                  <Route path="/video-upload" element={<UploadPage type="video" user={user} />} />
                }
                {user &&
                  <Route path="/document-upload" element={<UploadPage type="document" user={user} />} />
                }
                <Route path="/video/:id" element={<VideoPage />} />
                {user &&
                  <Route path="/video" element={<ResourceGrid user={user} resourceType="video" />} />
                }
                {user &&
                      <Route path="/podcast-upload" element={<UploadPage type="audio" user={user} />} />
                }
                <Route path="/document/:id" element={<DocumentPage />} />
                {user &&
                  <Route path="/document" element={<ResourceGrid user={user} resourceType="document" />} />
                }
                <Route path="/audio/:id" element={<AudioPage />} />
                {user &&
                      <Route path="/audio" element={<ResourceGrid user={user} resourceType="audio" />} />
                }
                <Route path="/workday/employee" element={<EmployeeLinks />} />
                <Route path="/workday/manager" element={<ManagerLinks />} />
                <Route path="/workday/hrbuspartner" element={<HRBusPartnerLinks />} />
                <Route path="/workday/ehwc" element={<EHWCLinks />} />
                <Route path="/workday/sdc" element={<EmployeeLinks />} />
                <Route path="/workday/recruiter" element={<RecruiterLinks />} />
                <Route path="/workday/finance" element={<FinanceLinks />} />
                <Route path="/ems/myevents" element={<EMSLinks />} />
                <Route path="/" element={<Home />} />
              </Routes>
              <Box pt={1} mt={20}>
                <Copyright />
              </Box>
            </Container>
          </main>

          <SideBar
            drawerWidth={drawerWidth}
            user={user}
            menuVisible={menuVisible}
            onLogout={logout}
            onNavigate={() => {
              setMenuVisible(false);
            }}
          />
        </ThemeProvider>
      </Router >
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  );
};
export default App;