import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { omit } from 'lodash';
import { CreateResourceInput, CreateResourceMutation, CreateUserInfoInput, CreateUserInfoMutation, GetResourceQuery, GetResourceQueryVariables, GetUserInfoQuery, GetUserInfoQueryVariables, SearchableResourceFilterInput, SearchResourcesQuery, SearchResourcesQueryVariables, UpdateResourceInput, UpdateResourceMutation, UpdateUserInfoInput, UpdateUserInfoMutation, ListResourcesQuery } from "../../API";
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

export default class ResourceService {

    private constructor() {

    }

    /**
     * Get a user info (video progress etc.)
     * 
     * @param id id 
     */
    static async getUserInfo(id: string): Promise<GraphQLResult<GetUserInfoQuery>> {
        return await API.graphql(graphqlOperation(queries.getUserInfo, { id } as GetUserInfoQueryVariables)) as GraphQLResult<GetUserInfoQuery>;
    }

    /**
     * Create new user info (store video progress etc.)
     * 
     * @param userInfo user info to be created
     */
    static async createUserInfo(userInfo: CreateUserInfoInput): Promise<GraphQLResult<CreateUserInfoMutation>> {
        return await API.graphql(graphqlOperation(mutations.createUserInfo,
            { input: ResourceService.getSafeUserInfoCopy(userInfo) })) as GraphQLResult<CreateUserInfoMutation>;
    }

    /**
     * Update existing user info (update video progress etc.)
     * 
     * @param userInfo user info to be updated
     */
    static async updateUserInfo(userInfo: UpdateUserInfoInput): Promise<GraphQLResult<UpdateUserInfoMutation>> {
        return await API.graphql(graphqlOperation(mutations.updateUserInfo,
            { input: ResourceService.getSafeUserInfoCopy(userInfo) })) as GraphQLResult<UpdateUserInfoMutation>;
    }

    /**
     * Get a copy of a user info object with only the expected attributes
     * 
     * @param resource user info object to copy
     */
    private static getSafeUserInfoCopy(userInfo: UpdateUserInfoInput | CreateUserInfoInput) {
        // Get a copy with only the expected attributes (exclude createdAt, updatedAt, etc.)
        return omit(userInfo, ['createdAt', 'updatedAt']);
    }

    /**
     * Get a resource (video or document)
     * 
     * @param id id 
     */
    static async getResource(id: string): Promise<GraphQLResult<GetResourceQuery>> {
        return await API.graphql(graphqlOperation(queries.getResource, { id } as GetResourceQueryVariables)) as GraphQLResult<GetResourceQuery>;
    }

    /**
     * Create a new resource (video or document)
     * 
     * @param resource resource to be created
     */
    static async createResource(resource: CreateResourceInput): Promise<GraphQLResult<CreateResourceMutation>> {
        return await API.graphql(graphqlOperation(mutations.createResource,
            { input: ResourceService.getSafeResourceCopy(resource) })) as GraphQLResult<CreateResourceMutation>;
    }

    /**
     * Update an existing resource (video or document)
     * 
     * @param resource resource to be updated
     */
    static async updateResource(resource: UpdateResourceInput): Promise<GraphQLResult<UpdateResourceMutation>> {
        return await API.graphql(graphqlOperation(mutations.updateResource,
            { input: ResourceService.getSafeResourceCopy(resource) })) as GraphQLResult<UpdateResourceMutation>;
    }

    /**
     * Get a copy of a resource input object with only the expected attributes
     * 
     * @param resource resource to copy
     */
    private static getSafeResourceCopy(resource: UpdateResourceInput | CreateResourceInput) {
        // Get a copy with only the expected attributes (exclude createdAt, updatedAt, etc.)
        return omit(resource, ['createdAt', 'updatedAt']);
    }

    /**
     * Get a presigned URL for downloading an S3 resource
     * 
     * @param s3Key key of the S3 object
     */
    static async getPresignedURL(s3Key: string): Promise<string> {
        return await Storage.get(s3Key, { download: false }) as string;
    }

    /**
     * Get title and description search filters for the given search string
     * 
     * @param searchText search text
     */
    private static getSearchFilters(searchText: string): SearchableResourceFilterInput[] {
        const titleFilters = searchText.split(' ').map(st => ({ title: { wildcard: '*' + st + '*' } } as SearchableResourceFilterInput));
        const descriptionFilters = searchText.split(' ').map(st => ({ description: { wildcard: '*' + st + '*' } } as SearchableResourceFilterInput));
        return [...titleFilters, ...descriptionFilters];
    }

    static async getAllResources(resourceType: string, searchText?: string, pageSize?: number, nextToken?: string): Promise<GraphQLResult<SearchResourcesQuery>> {
        const qVars: SearchResourcesQueryVariables = {
            filter: {
                and: [{
                    type: {  
                        eq: resourceType, 
                    }
                   
                } as SearchableResourceFilterInput],
               
            
                or: searchText ? this.getSearchFilters(searchText.toLowerCase()) : undefined 
            },
            limit: pageSize || 10,
            nextToken
        };
        return await API.graphql(graphqlOperation(queries.searchResources, qVars)) as GraphQLResult<SearchResourcesQuery>;
    }


    /**
     * 
     */

    /*static async getResourceAppName():Promise<GraphQLResult<ListResourcesQuery>> {

    } */
    
    
    
    /**
     * Search for a resource (video or document) in a particular Erickson Application by type and title / description 
     * 
     * @param resourceType resource type (video or document)
     * @param searchText search text
     * @param pageSize page size
     * @param nextToken next token
     */
    static async searchResources(resourceType: string, appType?: string, searchText?: string, pageSize?: number, nextToken?: string): Promise<GraphQLResult<SearchResourcesQuery>> {
        
       const qVars: SearchResourcesQueryVariables = {
            filter: {
                and: [{
                    type: {  
                        eq: resourceType, 
                    },
                    appAsString: {
                       eq: appType, 
                    }
                 
                   
                } as SearchableResourceFilterInput],
               
            
                or: searchText ? this.getSearchFilters(searchText.toLowerCase()) : undefined 
            },
            limit: pageSize || 10,
            nextToken
        };
        return await API.graphql(graphqlOperation(queries.searchResources, qVars)) as GraphQLResult<SearchResourcesQuery>;
    }
}