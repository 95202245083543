import { styled } from '@mui/material';
import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import VideoPlayerProps from './VideoPlayerProps';

const CustomReactPlayer = styled(ReactPlayer)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: '100% !important'
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: '90%'
    },
    height: 'initial !important'
}));

export default function VideoPlayer(props: VideoPlayerProps) {

    const playerRef = useRef<ReactPlayer | null>(null);

    return (
        <CustomReactPlayer
            ref={playerRef}
            url={props.url}
            controls={true}
            width="800px"
            progressInterval={120000}
            onReady={() => props.onReady(playerRef)}
            onProgress={() => props.onSaveState(playerRef)}
            onPause={() => props.onSaveState(playerRef)}
            onEnded={() => props.onSaveState(playerRef)}
        />
    );
}