import { useTheme } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import BreadcrumbBarProps from './BreadcrumbBarProps';

export default function BreadcrumbBar(props: BreadcrumbBarProps) {

    const theme = useTheme();

    const breadcrumbs = useBreadcrumbs(props.routes);

    return (
        <React.Fragment>
            {breadcrumbs.map(({
                match,
                breadcrumb
            }, index) => (
                <NavLink style={{ fontSize: '0.9em', textDecoration: 'none', color: theme.palette.primary.main }} to={match.pathname}>
                    {breadcrumb}
                    {index !== breadcrumbs.length - 1 && ' > '}
                </NavLink>
            ))}
        </React.Fragment>
    );
}