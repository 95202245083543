import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, TextField } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import LinkControlProps from './LinkControlProps';

export default function LinkControl(props: LinkControlProps) {

    const [title, setTitle] = useState<string>('');
    const [link, setLink] = useState<string>('');

    const [section, setSection] = useState<'mainLinks' | 'jobAids'>('mainLinks');
    const [index, setIndex] = useState<number>();
    const [numberOfLinks, setNumberOfLinks] = useState<number>(0);

    useEffect(() => {
        setSection(props.mainLinkIndex !== undefined ? 'mainLinks' : 'jobAids');
        setIndex((section === 'mainLinks' ? props.mainLinkIndex : props.jobAidIndex) || 0);
        setNumberOfLinks(props.categories[props.categoryIndex].subCategories[props.subCategoryIndex][section].length);
        // TODO: Update this:
        setTitle(getTitle());
        setLink(getLink());
    });

    const handleMove = (direction: 'up' | 'down') => {
        if (index === undefined) {
            return;
        }
        const dataCopy = [...props.categories];
        [dataCopy[props.categoryIndex].subCategories[props.subCategoryIndex][section][index],
        dataCopy[props.categoryIndex].subCategories[props.subCategoryIndex][section][index + (direction === 'up' ? -1 : 1)]] =
            [dataCopy[props.categoryIndex].subCategories[props.subCategoryIndex][section][index + (direction === 'up' ? -1 : 1)],
            dataCopy[props.categoryIndex].subCategories[props.subCategoryIndex][section][index]];
        props.onChange(dataCopy);
    }

    const handleDelete = () => {
        if (index === undefined) {
            return;
        }
        const dataCopy = [...props.categories];
        dataCopy[props.categoryIndex].subCategories[props.subCategoryIndex][section].splice(index, 1);
        props.onChange(dataCopy);
    }

    const isUpDisabled = () => {
        return index === undefined || index < 1;
    }

    const isDownDisabled = () => {
        return index === undefined || index >= numberOfLinks - 1;
    }

    const getLink = () => {
        if (index === undefined) {
            return '';
        }
        return props.categories[props.categoryIndex].subCategories[props.subCategoryIndex][section][index].link;
    }

    const getTitle = () => {
        if (index === undefined) {
            return '';
        }
        return props.categories[props.categoryIndex].subCategories[props.subCategoryIndex][section][index].title;
    }

    const updateLink = (title: string, link: string) => {
        if (index === undefined) {
            return;
        }
        const dataCopy = [...props.categories];
        const subCategory = dataCopy[props.categoryIndex].subCategories[props.subCategoryIndex];
        subCategory[section][index].title = title;
        subCategory[section][index].link = link; //sets the new link  for the new link we created in the  subcategory for a particular section and subcategory index; index is coming from line 26
        props.onChange(dataCopy);
    }

    const [dialogOpen, setDialogOpen] = useState(false);
    const [workingTitle, setWorkingTitle] = useState<string>(getTitle());
    const [workingLink, setWorkingLink] = useState<string>(getLink());
    const handleEditLink = () => {
        setWorkingLink(link);
        setWorkingTitle(title);
        setDialogOpen(true);
    }
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleLinkSave = () => {
        updateLink(workingTitle, workingLink);  //look here - 02/04/2021
        setDialogOpen(false);
    }
    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setWorkingTitle(e.target.value);
    }
    const handleLinkChange = (e: ChangeEvent<HTMLInputElement>) => {
        setWorkingLink(e.target.value);
    }

    return (
        <React.Fragment>
            {props.hideControls
                ?
                <Link href={link}>
                    <strong>{title}</strong>
                </Link>
                :
                <React.Fragment>
                    <Link>
                        <strong onClick={handleEditLink}>{title}</strong>
                    </Link>
                    <IconButton size="small" disabled={isUpDisabled()} onClick={() => handleMove('up')}>
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton size="small" disabled={isDownDisabled()} onClick={() => handleMove('down')}>
                        <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton size="small" onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </React.Fragment>}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Report Issue"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <label>Title</label>
                        <TextField fullWidth variant="filled" value={workingTitle} onChange={handleTitleChange} />
                        <label>Link</label>
                        <TextField fullWidth variant="filled" value={workingLink} onChange={handleLinkChange} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleLinkSave} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
