import { Box, Button, Chip, Grid, styled, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TagContainerProps from './TagContainerProps';

export default function TagContainer(props: TagContainerProps) {

    const TagContainerGrid = styled(Grid)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0
    }));

    const CustomChip = styled(Chip)(({ theme }) => ({
        margin: theme.spacing(0.5)
    }));

    const [tags, setTags] = useState<string[]>([]);

    const [currentTag, setCurrentTag] = useState<string>('');
    const handleCurrentTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentTag(e.target.value);
    }

    useEffect(() => {
        props.onChange(tags);
    }, [tags]);

    useEffect(() => {
        if (props.edit) {
            if (props.existingTags) {
                setTags(props.existingTags);
            }
        }

    }, []);

    const handleAdd = () => {
        if (currentTag) {
            setTags(tags => [...tags, currentTag]);
            setCurrentTag('');
        }
    }


    const handleDelete = (tagToDelete: string) => () => {
        setTags(tags.filter(tag => tag !== tagToDelete));
    };

    return (
        <Box sx={props.sx}>
            <Box display={'inline'}>
                <TextField
                    sx={{
                        backgroundColor: 'white'
                    }}
                    size="small"
                    value={currentTag}
                    onChange={handleCurrentTagChange} />
            </Box>
            <Box display={'inline'} paddingLeft={1}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAdd}>Add</Button>
            </Box>
            <Grid container sx={props.sx}>
                <TagContainerGrid item xs={12}>
                    {Array.from(tags).map((tag) => {
                        return (
                            <li key={tag}>
                                <CustomChip
                                    label={tag}
                                    onDelete={handleDelete(tag)}
                                />
                            </li>
                        );
                    })}
                </TagContainerGrid>
            </Grid>
        </Box>
    );
}