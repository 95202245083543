/* eslint-disable @typescript-eslint/no-use-before-define */
import { Storage } from "@aws-amplify/storage";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Button, ListItemIcon, Typography } from "@mui/material";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import BusyIndicator from "../BusyIndicator/BusyIndicator";
import careerImage from "./Assets/career.svg";
import clockImage from "./Assets/clock.svg";
import learningImage from "./Assets/learning.svg";
import navigationImage from "./Assets/navigation.svg";
import payImage from "./Assets/pay.svg";
import talentImage from "./Assets/talent.svg";
import videoImage from "./Assets/video.svg";
import CategoryData from "./CategoryData";
import CategoryProps from "./CategoryProps";
import LinkControl from "./LinkControl";

export default function Categories(props: CategoryProps) {
  const [error, setError] = useState<string>();

  const [hideControls, setHideControls] = useState<boolean>(true);
  const [categories, setCategories] = useState<CategoryData[]>();
  const [originalCategories, setOriginalCategories] = useState<CategoryData[]>();

  const [isAdmin, setIsAdmin] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>();
  //let isLoading = false;
  useEffect(() => {
    if (isAdmin === undefined) {
      Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
        const groups = user.getSignInUserSession()?.getAccessToken().payload["cognito:groups"] as string[];
        setIsAdmin(groups && groups.indexOf("admin") >= 0);
      });
    }
  });


  /**
   * Load the category data
   */
  useEffect(() => {
    const loadFile = async (fileName: string) => {
      try {
        const categoryDataRequest = await Storage.get(`pages/landingpages/${props.s3file}`, { download: true }) as any;
        console.log(categoryDataRequest);
        // data.Body is a Blob
        categoryDataRequest.Body.text().then((res: string) => {
          // handle the String data return String 
          console.log("---before---");
          console.log(res);
          const categoryData = JSON.parse(res);
          setCategories((categoryData as any).categories);
          setOriginalCategories(cloneDeep((categoryData as any).categories));
          console.log("---after---");
        });


      } catch (err) {
        const message = "Error loading category data";
        setError(`${message}: ${err}`);
        console.log(message, err);
      }
    };

    if (props.s3file) {
      loadFile(props.s3file);
    } else {
      setCategories(props.data);
    }
  }, [props.s3file, props.data]);

  const handleLinkAdd = (categoryIndex: number, subCategoryIndex: number, section: "mainLinks" | "jobAids") => {
    if (categories) {
      const copy = [...categories];
      copy[categoryIndex].subCategories[subCategoryIndex][section].push({ title: "New Link", link: "#" });
      setCategories(copy);
    }
  };

  const getIcon = (iconName: string) => {
    switch (iconName) {
      case "careerImage":
        return careerImage;
      case "clockImage":
        return clockImage;
      case "learningImage":
        return learningImage;
      case "navigationImage":
        return navigationImage;
      case "payImage":
        return payImage;
      case "videoImage":
        return videoImage;
      case "talentImage":
        return talentImage;
    }
    return iconName;
  };

  /* const sleep = (milliseconds: number) => {
     return new Promise((resolve) => setTimeout(resolve, milliseconds));
   };
 
   const wait = async (milliseconds = 4000) => {
     await sleep(milliseconds);
     setIsLoading(false);
   };*/

  const editPageButton = async () => {
    if (!hideControls) {
      if (props.s3file) {
        const categoriesString = JSON.stringify({ categories });
        const originalCategoriesString = JSON.stringify({ categories: originalCategories });
        if (categoriesString !== originalCategoriesString) {
          console.log("Saving...");
          setIsLoading(true);
          const extension = props.s3file.split(".").pop();
          const backupFile = props.s3file.replace(/\.[^/.]+$/, "") + "_" + new Date().toISOString() + "." + extension;
          // Make a backup
          await Storage.put(`pages/landingpages/backups/${backupFile}`, originalCategoriesString);
          // Save the new file
          await Storage.put(`pages/landingpages/backups/${props.s3file}`, categoriesString);
          // await Storage.put(`pages/workday/${props.s3file}`, categoriesString);
          await Storage.put(`pages/landingpages/${props.s3file}`, categoriesString).then(function () { setIsLoading(false); }).catch(err => console.log(err));
        }
      }
    }
    setHideControls(!hideControls);
  };

  return (
    <React.Fragment>
      {isLoading ? <BusyIndicator busy={true} /> :
        <React.Fragment>
          <p style={{ color: "red" }} hidden={!error}>
            An error has occurred: {error}
          </p>
          <div hidden={!isAdmin}>
            <br />


            <Button
              size="small"
              variant="contained"
              color={hideControls ? "secondary" : "primary"}
              onClick={editPageButton}
              startIcon={<EditIcon />}
            >
              {hideControls ? "Edit Page" : "Save Page"}
            </Button>
            <br />
          </div>
          {categories &&
            categories.map((category, categoryIndex) => (
              <div style={{
                display: "flex",
                width: "100%",
                marginBottom: 20
              }}>
                <div style={{
                  float: "left",
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingRight: 10
                }}>
                  <img src={getIcon(category.icon)} alt={category.title} width="48px" />
                </div>
                <div style={{
                  float: "left",
                  padding: 10
                }}>
                  <Typography variant="subtitle2" style={{
                    display: "flex",
                    alignItems: "center",
                    textTransform: "uppercase"
                  }}>
                    <strong>{category.title}</strong>
                  </Typography>
                  {category.subCategories.map((subCategory, subCategoryIndex) => (
                    <div style={{
                      float: "left",
                      padding: 10
                    }}>
                      <div style={{
                        float: "left",
                        padding: 10
                      }}>
                        <ListItemIcon>
                          <img src={getIcon(subCategory.icon)} alt={category.title} width="32px" />
                        </ListItemIcon>
                      </div>
                      <div style={{
                        float: "left",
                        padding: 10
                      }}>
                        <div hidden={hideControls}>
                          <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => handleLinkAdd(categoryIndex, subCategoryIndex, "mainLinks")}
                            startIcon={<AddIcon />}
                          >
                            Add Link
                          </Button>
                        </div>
                        <ul style={{
                          listStyle: "circle",
                          listStylePosition: "inside",
                          padding: 0,
                          margin: 0
                        }}>
                          {subCategory.mainLinks.map((mainLink, mainLinkIndex) => (
                            <li>
                              <LinkControl
                                hideControls={hideControls}
                                categories={categories}
                                categoryIndex={categoryIndex}
                                subCategoryIndex={subCategoryIndex}
                                mainLinkIndex={mainLinkIndex}
                                onChange={(categories) => setCategories(categories)}
                              />
                            </li>
                          ))}
                        </ul>
                        {subCategory.jobAids && subCategory.jobAids.length > 0 ? (
                          <Typography variant="subtitle2" sx={{
                            marginTop: 15
                          }}>
                            Job Aids
                          </Typography>
                        ) : null}
                        <div hidden={hideControls}>
                          <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => handleLinkAdd(categoryIndex, subCategoryIndex, "jobAids")}
                            startIcon={<AddIcon />}
                          >
                            Add Link
                          </Button>
                        </div>
                        <ul style={{
                          listStyle: "circle",
                          listStylePosition: "inside",
                          padding: 0,
                          margin: 0
                        }}>
                          {subCategory.jobAids && subCategory.jobAids.map((jobAid, jobAidIndex) => (
                            <li>
                              <LinkControl
                                hideControls={hideControls}
                                categories={categories}
                                categoryIndex={categoryIndex}
                                subCategoryIndex={subCategoryIndex}
                                jobAidIndex={jobAidIndex}
                                onChange={(categories) => setCategories(categories)}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}

        </React.Fragment>
      }
    </React.Fragment>
  );
}
