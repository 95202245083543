import { /* makeStyles, Theme, */ Typography } from "@mui/material";
import React from "react";
import { useDev } from "../BaseUrl";
import Categories from "../Categories";
import ContactInformation from "./WDContactInformation";

// const useStyles = makeStyles((theme: Theme) => ({}));

export default function EHWCLinks() {
  // const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h4">Quick Links for EHWC</Typography>
      <br />
      <Categories s3file={useDev ? "EHWCJSON_dev.json" : "EHWCJSON_prod.json"} /> 
      <br />
      <ContactInformation />
    </React.Fragment>
  );
}
