import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Card, CardContent, FormControl, Grid, IconButton, InputLabel, styled, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { GetResourceQuery } from '../../../../API';
import SideBarData from '../../../SideBar/SideBarData';
import SideBarService from '../../../SideBar/SideBarService';
import TagContainer from '../../../Upload/TagContainer';
import ResourceService from '../../ResourceService';
import ResourcePageProps from './ResourcePageProps';

export default function ResourcePage(props: PropsWithChildren<ResourcePageProps>) {

    /**
     * Styles
     */

    const CustomInputLabel = styled(InputLabel)(_ => ({
        paddingRight: 10,
        fontSize: '1rem',
        color: '#143b59',
        fontWeight: 'bold'
    }));

    const [resource, setResource] = useState<GetResourceQuery['getResource']>();
    const [originalResource, setOriginalResource] = useState<GetResourceQuery['getResource']>();
    const [formDirty, setFormDirty] = useState(false);

    const [titleEditMode, setTitleEditMode] = useState<boolean>(false);
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (resource) {
            setResource({ ...resource, title: e.target.value });
        }
    }

    const [descriptionEditMode, setDescriptionEditMode] = useState<boolean>(false);
    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (resource) {
            setResource({ ...resource, description: e.target.value });
        }
    }

    const handleAppChange = (e: SelectChangeEvent<string>) => {
        if (resource) {
            setResource({ ...resource, appAsString: e.target.value });
            setApp(e.target.value);
        }
    }

    const [tags, setTags] = useState<string[]>();


    const handleTagsChange = (tags: string[]) => {
        setTags(tags);
        if (resource) {
            setResource({ ...resource, tags: tags });
        }
    }

    const [appMenuItems, setAppMenuItems] = React.useState<SideBarData[]>();
    const [app, setApp] = useState<string>('');
    const [error, setError] = useState<string>();
    const handleEdit = async () => {
        try {
            const appMainMenuItemsQuery = await SideBarService.getAllApplications();
            const appMainMenuItems = appMainMenuItemsQuery.data?.listApplications;
            setAppMenuItems(appMainMenuItems?.items as SideBarData[]);
            console.log(appMenuItems);
        } catch (err) {
            const message = "Error loading data";
            setError(`${message}: ${err}`);
            console.log(message, err);
        }
        if (resource?.appAsString) {
            setApp(resource?.appAsString);
        }
    };

    const [groups, setGroups] = useState<string[]>();
    useEffect(() => {
        if (!groups) {
            setGroups(props.user?.getSignInUserSession()?.getAccessToken().payload['cognito:groups']);
        }
    });

    const reloadForm = () => {
        if (props.id) {
            ResourceService.getResource(props.id).then(resourceQuery => {
                if (resourceQuery.data) {
                    setResource(resourceQuery.data.getResource);
                    setOriginalResource(resourceQuery.data.getResource);
                    props.onResourceLoaded(resourceQuery.data.getResource);
                } else {
                    // TODO: implementƒ
                    // setError()
                }
            });
        }
    }

    useEffect(() => {
        reloadForm();
    }, []);

    useEffect(() => {
        setFormDirty(JSON.stringify(resource) !== JSON.stringify(originalResource));
    }, [resource]);

    return (
        <>
            <Box paddingBottom={1}>
                <Grid container>
                    <Grid item>
                        {!titleEditMode ?
                            <Typography variant="h6" component="h2" gutterBottom>
                                <strong>{resource?.title || "Untitled Resource"}</strong>
                            </Typography>
                            :
                            <TextField value={resource?.title} onChange={handleTitleChange} size="small" sx={{ backgroundColor: 'white' }} />}
                    </Grid>
                    {groups && groups.indexOf('admin') >= 0 && <Grid item>
                        {!titleEditMode ?
                            <IconButton size="small" onClick={() => setTitleEditMode(true)}>
                                <EditIcon color="primary" />
                            </IconButton>
                            :
                            <IconButton size="small" onClick={() => {
                                setTitleEditMode(false);
                                if (resource) {
                                    props.onResourceUpdate(resource);
                                }
                            }}>
                                <SaveIcon color="primary" />
                            </IconButton>
                        }
                    </Grid>}
                </Grid>
            </Box>
            <div>
                {props.children}
            </div>

            {groups && groups.indexOf('admin') >= 0 &&
                <Card sx={{ maxWidth: '800px', backgroundColor: 'darkgray' }}>
                    <Box p={1}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Box pt='5px' pl={1}>
                                    {(descriptionEditMode && formDirty) &&
                                        <Typography variant="subtitle2" sx={{ color: 'red', fontWeight: 'bold' }}>Unsaved changes</Typography>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row-reverse'
                                    }}
                                >
                                    <Box ml={1}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            disabled={!descriptionEditMode && formDirty}
                                            onClick={() => {
                                                setDescriptionEditMode(false);
                                                if (resource) {
                                                    props.onResourceUpdate(resource);
                                                }
                                            }}>
                                            Save Changes
                                        </Button>
                                    </Box>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => { setDescriptionEditMode(!descriptionEditMode); handleEdit(); reloadForm(); }}>
                                        {!descriptionEditMode ? 'Edit Attributes' : 'Cancel'}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            }
            <Card variant="outlined" sx={{ maxWidth: '800px' }}>
                <CardContent>
                    <Box paddingBottom={2}>
                        <Grid container>
                            <Grid item sm={2}>
                                <CustomInputLabel id="description-label">
                                    Description:
                                </CustomInputLabel>
                            </Grid>
                            <Grid item>
                                {!descriptionEditMode ?
                                    <Typography variant="subtitle2" component="h2">
                                        {resource?.description}
                                    </Typography>
                                    :
                                    <TextField value={resource?.description} onChange={handleDescriptionChange} size="small" sx={{ backgroundColor: 'white' }} />}
                            </Grid>
                        </Grid>
                    </Box>

                    <Box paddingBottom={2}>
                        <Grid container>
                            <Grid item sm={2}>
                                <CustomInputLabel id="application-label">
                                    Application:
                                </CustomInputLabel>
                            </Grid>
                            <Grid item>
                                {!descriptionEditMode ?
                                    <Typography variant="subtitle2" component="h2">
                                        {resource?.appAsString}
                                    </Typography>
                                    :
                                    <div>
                                        <FormControl>
                                            <Select labelId="application-label"
                                                id="app-label" variant="outlined"
                                                onChange={handleAppChange}
                                                value={app}
                                                size="small"
                                                sx={{ backgroundColor: 'white' }}
                                            >
                                                <MenuItem value="">None</MenuItem>
                                                {appMenuItems &&
                                                    appMenuItems.map((appMenuItem, appMenuItemIndex) => (
                                                        <MenuItem value={appMenuItem.appName}>{appMenuItem.appName}</MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl></div>
                                }
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container>
                            <Grid item sm={2}>
                                <CustomInputLabel id="tags-label">
                                    Tags:
                                </CustomInputLabel>
                            </Grid>
                            <Grid item sm={9}>
                                {!descriptionEditMode ?
                                    <Typography variant="subtitle2" component="h2">
                                        {resource?.tags && resource?.tags.join(', ')}
                                    </Typography>
                                    :
                                    <TagContainer onChange={handleTagsChange} edit={true} existingTags={resource?.tags} />
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}