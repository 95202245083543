import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PublishIcon from "@mui/icons-material/Publish";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Divider, Hidden, IconButton, SxProps, Theme } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Boolean } from "aws-sdk/clients/apigateway";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import SideBarData from "./SideBarData";
import SideBarProps from "./SideBarProps";
import SideBarService from "./SideBarService";

const iconStyle = {
  color: 'white'
} as SxProps<Theme>;

const nestItemStyle = {
  paddingLeft: 2
} as SxProps<Theme>;

const nestedIconStyle = {
  color: 'white',
  fontSize: '2.5rem',
  paddingLeft: 2
} as SxProps<Theme>;

export default function SideBar(props: SideBarProps) {
  const drawerWidth = props.drawerWidth;

  const [error, setError] = useState<string>();
  const [collapseInfo, setCollapseInfo] = React.useState<Boolean[]>([]);
  const [appMenuItems, setAppMenuItems] = React.useState<SideBarData[]>();

  /*load the menu items for the applications only after first render */
  useEffect(() => {
    const loadMainAppItems = async () => {
      try {
        const appMainMenuItemsQuery = await SideBarService.getAllApplications();
        const appMainMenuItems = appMainMenuItemsQuery.data?.listApplications;
        setAppMenuItems(appMainMenuItems?.items as SideBarData[]);
        if (appMenuItems) {
          const arrLength = appMenuItems.length;
          setCollapseInfo([...Array(arrLength)].map(x => false));
        }
      } catch (err) {
        const message = "Error loading data";
        setError(`${message}: ${err}`);
        console.log(message, err);
      }
    };
    loadMainAppItems();
  }, []);

  /*For the application menu items */

  const handleClick = (menuIndex: number) => {
    const copy = [...collapseInfo];
    copy[menuIndex] = !collapseInfo[menuIndex];
    console.log(collapseInfo);
    setCollapseInfo(copy);
  };

  const [groups, setGroups] = useState<string[]>();
  useEffect(() => {
    setGroups(props.user?.getSignInUserSession()?.getAccessToken().payload["cognito:groups"]);
  }, [props.user]);

  return (
    <Hidden smDown={!props.menuVisible}>
      <Drawer
        variant="permanent"
        sx={{
          width: props.menuVisible ? "100%" : drawerWidth,
          flexShrink: 0,
          '& .MuiList-padding': {
            marginTop: 3
          },
          '& .MuiDrawer-paper': {
            zIndex: 1,
            marginTop: '84px !important',
            width: props.menuVisible ? "100%" : drawerWidth,
            color: "white",
            backgroundColor: "rgb(20, 59, 89)"
          }
        }}
        anchor="right"
      >
        <div>
          <Hidden smUp>
            <IconButton onClick={props.onNavigate}>
              <CloseIcon sx={iconStyle} />
            </IconButton>
          </Hidden>
        </div>
        <List sx={{ marginTop: 25 }}>
          {groups && groups.indexOf("admin") >= 0 && (
            <RouterLink to="/admin" style={{ textDecoration: "none", color: "white" }}>
              <ListItem button key="" onClick={props.onNavigate}>
                <ListItemIcon>
                  <SupervisorAccountIcon sx={iconStyle} />
                </ListItemIcon>
                <ListItemText primary={`Manage Users ${props.menuVisible}`} />
              </ListItem>
            </RouterLink>
          )}
          {groups && groups.indexOf("admin") >= 0 && (
            <RouterLink to="/video-upload" style={{ textDecoration: "none", color: "white" }}>
              <ListItem button key="" onClick={props.onNavigate}>
                <ListItemIcon>
                  <PublishIcon sx={iconStyle} />
                </ListItemIcon>
                <ListItemText primary="Upload Video" />
              </ListItem>
            </RouterLink>
          )}
          {groups && groups.indexOf("admin") >= 0 && (
            <RouterLink to="/podcast-upload" style={{ textDecoration: "none", color: "white" }}>
              <ListItem button key="" onClick={props.onNavigate}>
                <ListItemIcon>
                  <PublishIcon sx={iconStyle} />
                </ListItemIcon>
                <ListItemText primary="Upload Podcast" />
              </ListItem>
            </RouterLink>
          )}
          {groups && groups.indexOf("admin") >= 0 && (
            <RouterLink to="/document-upload" style={{ textDecoration: "none", color: "white" }}>
              <ListItem button key="" onClick={props.onNavigate}>
                <ListItemIcon>
                  <PublishIcon sx={iconStyle} />
                </ListItemIcon>
                <ListItemText primary="Upload Document" />
              </ListItem>
            </RouterLink>
          )}
          <ListItem button key="">
            <ListItemText primary="Landing Pages" />
          </ListItem>
          {/* I might add a collapse here */}
          {appMenuItems &&
            appMenuItems.map((appMenuItem, appMenuItemIndex) => (
              <List>
                {appMenuItem.menuVisible &&
                  <List><ListItem button onClick={(e) => handleClick(appMenuItemIndex)}>
                    <ListItemIcon>
                      <MenuBookIcon sx={nestedIconStyle} />
                    </ListItemIcon>
                    <ListItemText primary={appMenuItem.appName} sx={nestItemStyle} />

                    {collapseInfo[appMenuItemIndex] ? <ExpandLess /> : <ExpandMore />}

                  </ListItem>
                    <Collapse in={collapseInfo[appMenuItemIndex]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {appMenuItem.landingPages && appMenuItem.landingPages.map((landingPage, landingPageIndex) => (
                          <RouterLink to={`${landingPage.url}`} style={{ textDecoration: "none", color: "white" }}>
                            <ListItem button key="" onClick={props.onNavigate} sx={nestItemStyle}>
                              <ListItemIcon>
                                <MenuBookIcon sx={nestedIconStyle} />
                              </ListItemIcon>
                              <ListItemText primary={landingPage.title} />
                            </ListItem>
                          </RouterLink>
                        ))}
                      </List>
                    </Collapse>
                  </List>
                }
              </List>

            ))}
        </List>

        <RouterLink to="/video" style={{ textDecoration: "none", color: "white" }}>
          <ListItem button key="" onClick={props.onNavigate} sx={nestItemStyle}>
            <ListItemIcon>
              <MenuBookIcon sx={iconStyle} />
            </ListItemIcon>
            <ListItemText primary="Video Catalog" />
          </ListItem>
        </RouterLink>

        <RouterLink to="/audio" style={{ textDecoration: "none", color: "white" }}>
          <ListItem button key="" onClick={props.onNavigate} sx={nestItemStyle}>
            <ListItemIcon>
              <MenuBookIcon sx={iconStyle} />
            </ListItemIcon>
            <ListItemText primary="Podcast Catalog" />
          </ListItem>
        </RouterLink>
        <Divider />
        <List dense>
          <ListItem button key="" onClick={props.onNavigate}>
            <ListItemText onClick={props.onLogout} primary="Log Out" />
          </ListItem>
        </List>
      </Drawer>
    </Hidden>
  );
}
