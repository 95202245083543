import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { omit } from 'lodash';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
   
import {GetApplicationQuery, GetApplicationQueryVariables, ListApplicationsQuery, ListApplicationsQueryVariables  } from "../../API";


export default class SideBarService {
    private constructor() {

    }

/**
     * Get the application menu items 
     * 
     *  
     */
    static async getAllApplications(): Promise<GraphQLResult<ListApplicationsQuery>> {
        return await API.graphql(graphqlOperation(queries.listApplications)) as GraphQLResult<ListApplicationsQuery>;
    }



    /**
     * Get the application sub menu items
     * 
     *  
     */
    /*static async getAppMenuItems(id: number): Promise<GraphQLResult<GetAppLandingPagesQuery>> {
        const qVars: SearchAppMenuItemssQueryVariables = {
            filter: {
                and: [{
                    appID: {  
                        eq: id, 
                    }
                   
                } as SearchableAppMenuItemsFilterInput]
            }
        };
        return await API.graphql(graphqlOperation(queries.searchAppMenuItemss, qVars)) as GraphQLResult<GetAppLandingPagesQuery>;
    } */


}




