import { Button, debounce, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Pagination, styled, TextField, Typography } from "@mui/material";
import Icon from '@mui/material/Icon';
import QRCode from 'qrcode.react';
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { UpdateResourceInput } from "../../API";
import AppFilter from "../AppFilter/AppFilter";
import SearchBox from "../SearchBox/SearchBox";
import ResourceThumbnail from "./Parts/ResourceThumbnail";
import ResourceGridProps from "./ResourceGridProps";
import ResourceService from "./ResourceService";

const ResponsiveGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    }
}));

function ResourceGridInner(props: ResourceGridProps) {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [columns, setColumns] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>('');
    const [appType, setAppType] = useState<string>('all');
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [pages, setPages] = useState<Map<number, string>>(new Map());
    const [pageCount, setPageCount] = useState<number>(1);
    const [resources, setResources] = useState<UpdateResourceInput[]>();
    const [open, setOpen] = useState(false);
    const [dialogResource, setDialogResource] = useState<UpdateResourceInput>();
    const [page, setPage] = React.useState(1);

    /**
    * Get the number of columns in the grid
    */
    const targetRef = useRef<any>();
    const getPageSize = () => columns * 4;
    const updatePageSize = () => {
        if (targetRef.current) {
            setColumns(Math.floor(targetRef.current.offsetWidth / 275));
        }
    }
    useLayoutEffect(() => {
        updatePageSize();
    }, []);
    let movement_timer: NodeJS.Timeout | null = null;
    const RESET_TIMEOUT = 500;
    window.addEventListener('resize', () => {
        if (movement_timer) {
            clearInterval(movement_timer);
        }
        movement_timer = setTimeout(updatePageSize, RESET_TIMEOUT);
    });

    useEffect(() => {
        console.log('Number of columns changed');
        handlePageRefresh(null, currentPage, appType, searchText);
    }, [columns]);

    const handlePageRefresh = async (event: React.ChangeEvent<unknown> | null, page: number, appType?: string, searchText?: string) => {
        setCurrentPage(page);
        if (searchText !== undefined) {
            setSearchText(searchText);
        }
        console.log(appType);
        console.log(searchText);
        console.log(page);
        const pageToken = pages.get(page);
        const pageSize = getPageSize();
        if (pageSize) {
            const searchResourcesQuery = (appType == "all") ? await ResourceService.getAllResources(props.resourceType, searchText, pageSize, pageToken) :
                await ResourceService.searchResources(props.resourceType, appType, searchText, pageSize, pageToken);

            const searchResources = searchResourcesQuery.data?.searchResources;
            console.log(searchResources?.nextToken);
            if (searchResources?.nextToken) {
                setPages(new Map(pages.set(page + 1, searchResources?.nextToken)));
            }
            setPageCount(Math.ceil((searchResources?.total || 1) / getPageSize()));
            setResources(searchResources?.items as UpdateResourceInput[]);
            window.scrollTo(0, 0);
            console.log('pages', pages);
        }
    }

    const handleSearch = debounce((searchText: string) => {
        setPages(new Map());
        handlePageRefresh(null, 1, appType, searchText);
    }, 750);

    const handleAppFilter = (appType: string) => {
        setPages(new Map());
        setAppType(appType);
        handlePageRefresh(null, 1, appType, undefined);
    }

    const displayShowLinkDialog = (resource: UpdateResourceInput) => {
        setDialogResource(resource);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(e.target.value);
    }

    const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        handlePageRefresh(null, value, appType, searchText);
    }

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <AppFilter resourceType={props.resourceType} onFilter={handleAppFilter}></AppFilter>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <SearchBox onSearch={handleSearch} />
                </Grid>
            </Grid>

            <br />
            <Grid container ref={targetRef}>
                {resources?.map((resource) => (
                    <ResponsiveGrid item key={resource.id}>
                        <ResourceThumbnail
                            user={props.user}
                            resource={resource}
                            showOverlay={props.resourceType === 'video' || props.resourceType === 'audio'}
                            lockAspectRatio={props.resourceType === 'video' || props.resourceType === 'audio'}
                            onShareLink={displayShowLinkDialog} />
                    </ResponsiveGrid>
                ))}
            </Grid>
            <Grid
                container
                justifyContent="center"
                alignItems="center">
                <Grid item>
                    <Pagination onChange={handlePagination} count={pageCount} page={page} shape="rounded" />
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Share {dialogResource?.title}</DialogTitle>
                <DialogContent>
                    {dialogResource?.description &&
                        <Typography variant="subtitle2"><em>Description:</em> {dialogResource?.description}</Typography>}
                    <DialogContentText id="alert-dialog-description">
                        <QRCode
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                            size={200}
                            value={window.location.href + "/" + dialogResource?.id}
                        />
                        <div>
                            <TextField fullWidth label="Email address" value={emailAddress} onChange={handleEmailChange} />
                        </div>
                        <br />
                        <Button
                            disabled={emailAddress.length < 1}
                            href={`mailto:${emailAddress}?subject=${encodeURIComponent('Training Hub - ' + dialogResource?.title) || ''}&body=${encodeURIComponent('Link: ' + window.location.href + "/" + dialogResource?.id) || ''}`}
                            variant="contained"
                            color="primary"
                            endIcon={<Icon />}>
                            Email Link
                        </Button>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

/**
 * Re-render underlying component when the resource type changes (ex. from video page to podcast page)
 * @param props 
 * @returns 
 */
export default function ResourceGrid(props: ResourceGridProps) {
    return (
        <ResourceGridInner key={props.resourceType} {...props} />
    );
}