import { API, graphqlOperation } from "aws-amplify";
import { omit } from 'lodash';
import { CreateUserInfoInput, UpdateUserInfoInput } from "../../../../API";
import * as mutations from '../../../../graphql/mutations';
import ResourceService from "../../ResourceService";

export default class AudioService {

    private constructor() {

    }

    //static async getNumberOfWatchedAudios(userId: string): Promise<number> {
        static async getNumberOfWatchedAudios(userId: string) {
        const audios = await (await ResourceService.getUserInfo(userId)).data?.getUserInfo?.audios || [];
        //return audios.filter(v => v.progress === 1).length;
        return null;
    }

    static async getProgress(userId: string, audioId: string) {
        const audios = await (await ResourceService.getUserInfo(userId)).data?.getUserInfo?.audios || [];
        return audios.find(v => v.audioId === audioId);
    }

    static async saveProgress(userId: string, audioId: string, played: number, secondsWatched: number) {
        let userInfo = await (await ResourceService.getUserInfo(userId)).data?.getUserInfo;
        if (!userInfo) {
            const createdUserInfo = await (await ResourceService.createUserInfo({ id: userId, videos: [], audios: [] })).data?.createUserInfo;
            if (createdUserInfo) {
                userInfo = { ...createdUserInfo };
            } else {
                const errMessage = 'Error creating user info';
                console.log(errMessage, createdUserInfo);
                throw new Error(errMessage);
            }
        }
        if (!userInfo.audios) {
            userInfo['audios'] = [];
           console.log("no audio history exists for user....", userInfo.audios);
        }

        const audios = userInfo.audios || [];
        console.log('Searching for audio id', audioId);
        let audioIndex = audios?.findIndex(v => v.audioId === audioId);
        if (audioIndex >= 0) {
            console.log('Found id', audioId);
            audios[audioIndex].location = played;
            audios[audioIndex].secondsWatched = secondsWatched;
            console.log('Seconds Watched from AudioService.ts', secondsWatched);
            if (!audios[audioIndex].progress || audios[audioIndex].progress < played) {
                audios[audioIndex].progress = played;
            }
        } else {
            console.log('No id found', audioId);
            const audio = { audioId, location: played, progress: played, secondsWatched: secondsWatched };
            userInfo.audios?.push(audio as any);
        }
        console.log('Saving progress', userInfo);
        return await API.graphql(graphqlOperation(mutations.updateUserInfo, { input: AudioService.getSafeUserInfoCopy(userInfo) }));
    } 

    /**
     * Get a copy of a resource input object with only the expected attributes
     * 
     * @param resource resource to copy
     */
    private static getSafeUserInfoCopy(resource: UpdateUserInfoInput | CreateUserInfoInput) {
        // Get a copy with only the expected attributes (exclude createdAt, updatedAt, etc.)
        return omit(resource, ['createdAt', 'updatedAt']);
    }

}