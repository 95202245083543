import { Theme, Typography } from "@mui/material";
import React from "react";
import { useDev } from "../BaseUrl";
import Categories from "../Categories";

export default function EMSLinks() {
  return (
    <React.Fragment>

      <Typography variant="h4">Quick Links for EMS/MyEvents</Typography>
      <Categories s3file={useDev ? "EMSJSON_dev.json" : "EMSJSON_prod.json"} />
    </React.Fragment>
  );
}