import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import AudioPlayerProps from './AudioPlayerProps';

export default function AudioPlayer(props: AudioPlayerProps) {

    const playerRef = useRef<ReactPlayer | null>(null);

    return (
        <ReactPlayer
            ref={playerRef}
            url={props.url}
            controls={true}
            height={35}
            listenInterval={120000}
            progressInterval={120000}
            onReady={() => props.onReady(playerRef)}
            onProgress={() => props.onSaveState(playerRef)}
            onPause={() => props.onSaveState(playerRef)}
            onEnded={() => props.onSaveState(playerRef)}
        />
    );
}